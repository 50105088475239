import React, {useEffect, useState} from "react"

import PropTypes from "prop-types"
import theme from "../../theme"
import {ThemeProvider} from "@material-ui/styles"
import Footer from "./Footer"
import Header from "./Header"
import {Helmet} from "react-helmet"
import {styled} from "@mui/material";

const Loading = styled('div')(() => ({
    position: 'fixed',
    top: '0',
    left: '0',
    height: '100vh',
    width: '100%',
    fontSize: '30px',
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
}))

const Layout = ({children}) => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 200);
    }, [])

    if (loading === true) return <Loading>Cargando...</Loading>
    return (<div>
        <Helmet>
            <meta charSet="utf-8"/>
            <title>Capital Funding Lab</title>
        </Helmet>
        <noscript>
            {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PZN3P5M" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
        </noscript>
        <ThemeProvider theme={theme}>
            <Header/>
            <main>
                {children}
            </main>
            <Footer/>
        </ThemeProvider>
    </div>)
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
